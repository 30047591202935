import React, { useEffect } from 'react';
import { Helmet } from "react-helmet"
import { ThemeProvider, Box } from '@material-ui/core';
import theme from '../theme';
import Header from '../components/Header';
import CopyRight from '../components/CopyRight';
import {getEntities, getWaterbodies, getEntityCoordinates, getGalleryImages, getLatestMapValues, getWaterBodyDetails} from '../services/entity';
import {_listMapToOptions, _listToOptions, _valToOption} from '../utils/dropdown'
import HomeComponent from '../components/HomeComponent';
import WaterBodyComponent from '../components/WaterBodyComponent';


export default function Home() {
  const [dashboard1, setDashboard1] = React.useState(35);
  const [dashboard2, setDashboard2] = React.useState(36);
  const [dashboard3, setDashboard3] = React.useState(37);

  const [entityType, setEntityType] = React.useState('country');
  const [states, setStates] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [blocks, setBlocks] = React.useState([]);
  const [villages, setVillages] = React.useState([]);
  const [waterBodies, setWaterBodies] = React.useState([]);

  const [country, setCountry] = React.useState('INDIA');
  const [state, setState] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [block, setBlock] = React.useState(null);
  const [village, setVillage] = React.useState(null);
  const [waterBody, setWaterBody] = React.useState(null);
  const [waterBodyName, setWaterBodyName] = React.useState(null);

  const [galleryImageList, setGalleryImageList] = React.useState([]);
  const [entityCoordinates, setEntityCoordinates] = React.useState(null);
  const [waterBodyMapDetails, setWaterBodyMapDetails] = React.useState(null);

  useEffect(() => {
    setCountryValue()
  }, []);


  const nullifyChildDropdownsWithType = (type) => {
    if(type === "country"){
      setState(null)
      setDistrict(null)
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "state"){
      setDistrict(null)
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "district"){
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "block"){
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "village"){
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
  }

  const setCountryValue = () => {
    getGalleryImages({}).then(resp => setGalleryImageList(resp))
    setEntityType('country')
    getEntityCoordinates({'type': 'country', 'name': country}).then(resp => setEntityCoordinates(resp))
    getEntities({'type': 'state'}).then(resp => {resp.sort(); setStates(resp)})
    getWaterbodies({}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('country')
    setDashboard('country')
  }

  const setStateValue = (value) => {
    getGalleryImages({state: value}).then(resp => setGalleryImageList(resp))
    setEntityType('state')
    setState(value);
    getEntityCoordinates({'type': 'state', 'name': value}).then(resp => setEntityCoordinates(resp))
    getEntities({'type': 'district', 'parent': value}).then(resp => {resp.sort(); setDistricts(resp)})
    getWaterbodies({'state': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('state')
    setDashboard('state')
  }

  const setDistrictValue = (value) => {
    setEntityType('district')
    setDistrict(value);
    getEntities({'type': 'block', 'parent': value}).then(resp => {resp.sort(); setBlocks(resp)})
    getEntityCoordinates({'type': 'district', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('district')
    setDashboard('district')
  }

  const setBlockValue = (value) => {
    setEntityType('block')
    setBlock(value);
    getEntities({'type': 'village', 'parent': value}).then(resp => {resp.sort(); setVillages(resp)})
    getEntityCoordinates({'type': 'block', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': district, 'block': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('block')
    setDashboard('block')
  }

  const setVillageValue = (value) => {
    setEntityType('village')
    setVillage(value);
    getEntityCoordinates({'type': 'village', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': district, 'block': block, 'village': value}).then(resp => {resp.sort(); setWaterBodies(resp)})
    nullifyChildDropdownsWithType('village')
    setDashboard('village')
  }

  const setSetDistrictBlockVillageBasedOnWaterBody = (waterBody) => {
    getWaterbodies({'state': waterBody.state, 'district': waterBody.district, 'block': waterBody.block, 'village': waterBody.village}).then(resp => {resp.sort(); setWaterBodies(resp)})
    getGalleryImages({state: waterBody.state}).then(resp => setGalleryImageList(resp))

    if(village){
        // All the filters are selected and have options
    }
    else if(block){
        // Village is not selected and all the filters have options
        setVillage(waterBody.village)
    }
    else if(district){
        // Block and Village not selected and Village filters don't have options
        setBlock(waterBody.block)
        setVillage(waterBody.village)
        getEntities({'type': 'village', 'parent': waterBody.block}).then(resp => {resp.sort(); setVillages(resp)})
    }
    else if(state){
        // District, Block and Villages not selected and Block, Village filters don't have options
        setDistrict(waterBody.district)
        setBlock(waterBody.block)
        setVillage(waterBody.village)
        getEntities({'type': 'block', 'parent': waterBody.district}).then(resp => {resp.sort(); setBlocks(resp)})
        getEntities({'type': 'village', 'parent': waterBody.block}).then(resp => {resp.sort(); setVillages(resp)})
    }
    else {
        // State, District, Block and Villages not selected and District, Block, Village filters don't have options
        setState(waterBody.state)
        setDistrict(waterBody.district) 
        setBlock(waterBody.block)
        setVillage(waterBody.village)
        getEntities({'type': 'district', 'parent': waterBody.state}).then(resp => {resp.sort(); setDistricts(resp)})
        getEntities({'type': 'block', 'parent': waterBody.district}).then(resp => {resp.sort(); setBlocks(resp)})
        getEntities({'type': 'village', 'parent': waterBody.block}).then(resp => {resp.sort(); setVillages(resp)})
    }
  }

  const setWaterBodyValue = (value) => {
    setEntityType('water_body')
    setWaterBodyName(value.name)
    getWaterBodyDetails({'id': value.water_body_id}).then(resp => setWaterBody(resp))
    getLatestMapValues({'water_body_id': value.water_body_id}).then(resp => setWaterBodyMapDetails(resp))
    setSetDistrictBlockVillageBasedOnWaterBody(value)
  }

  const setDashboard = (entityType) => {
    setDashboard1(entityToDashboard1[entityType])
    setDashboard2(entityToDashboard2[entityType])
    setDashboard3(entityToDashboard3[entityType])
  }

  const entityToDashboard1 = {
    'country': 35,
    'state': 39,
    'district': 42,
    'block': 45,
    'village': 48
  }

  const entityToDashboard2 = {
    'country': 36,
    'state': 40,
    'district': 43,
    'block': 46,
    'village': 49
  }

  const entityToDashboard3 = {
    'country': 37,
    'state': 41,
    'district': 44,
    'block': 47,
    'village': 50
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name='description' content='Namami Gange Dashboard!' />
        <meta name='owner' content='https://www.qcin.org/' />
        <meta name='developer' content='https://dataorc.in/' />
        <title>Namami Gange Dashboard</title>
      </Helmet>

      <ThemeProvider theme={theme}>

        <Header isSelectBar={true} states={states} districts={districts} blocks={blocks} villages={villages} waterBodies={waterBodies}
        state={state} district={district} block={block} village={village} waterBody={waterBody}
        setCountryValue={setCountryValue} height={"160px"}
        setStateValue={setStateValue} setDistrictValue={setDistrictValue} setBlockValue={setBlockValue} setVillageValue={setVillageValue} setWaterBodyValue={setWaterBodyValue}
        />

        <Box
            sx={{
            backgroundColor: '#d3d5e5',
            minHeight: '100%',
            pt: 3,
            pb: 3
            }}
            style={{background: '#d3d5e5'}}
        >
            {
                entityType !== "water_body" ?
                <HomeComponent 
                entityType={entityType} 
                state={state} 
                district={district} 
                block={block} 
                village={village}
                waterBody={waterBody}
                dashboard1={dashboard1}
                dashboard2={dashboard2}
                dashboard3={dashboard3}
                galleryImageList={galleryImageList}
                waterBodies={waterBodies}
                entityCoordinates={entityCoordinates}
                setWaterBodyValue={setWaterBodyValue}
                /> 
                :
                <WaterBodyComponent 
                waterBodyName={waterBodyName}
                waterBody={waterBody}
                waterBodyMapDetails={waterBodyMapDetails}
                />
            }
            
        </Box>

        <CopyRight />
      </ThemeProvider>
    </>
    
  );
}
