import React from 'react';
import { Container, Grid } from '@material-ui/core';
import MetabaseDashboard from '../components/MetabaseDashboard';
import MapCard from '../components/MapCard';
import {_listMapToOptions, _listToOptions, _valToOption} from '../utils/dropdown'
import SlidShowCard from '../components/SlideShowCard';
import Item from '../components/ItemComponent'


export default function HomeComponent({entityType, state, district, block, village, waterBody, 
    dashboard1, dashboard2, dashboard3, galleryImageList, entityCoordinates, waterBodies, setWaterBodyValue}) {

    const shapeColorList = [{'name': 'Healthy', 'color': 'blue'},
      {'name': 'Eutrophication', 'color': 'black'},
      {'name': 'Dried-Up', 'color': 'red'}, 
    ]

    return (
        <>
            <Container maxWidth={false}>
              <Grid container spacing={2}>

                  <Grid item xs={12}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard1} 
                          height={entityType === "village" ? "360px" : "600px"}
                          entityType={entityType} state={state} district={district} block={block} village={village}
                        />
                      </Item>
                  </Grid>

                  <Grid item xs={6}>
                      <Item>
                      <div className="something" style={{height: '100%'}}>
                          <div style={{display: "flex", justifyContent: "right"}}>
                          {shapeColorList.map(s => <div>
                                  <div style={{ width: "12px",
                                    display: "inline-block",
                                    height: "10px",
                                    background: s.color,
                                    margin: "3px 8px",
                                    borderRadius: "2px"
                                  }}>
                                  </div> {s.name}
                                </div>
                          )}
                          </div>
                            <MapCard
                            isMisMarkerShown={true}
                            waterBodies={waterBodies}
                            waterBody={waterBody}
                            waterBodyMapDetails={null}
                            entity={village || block || district || state}
                            contourToggle={true}
                            orthoToggle={true}
                            shapesToggle={true}
                            entityCoordinates={entityCoordinates}
                            height={entityType === 'village' ? "780px" : "1170px"}
                            setWaterBodyValue={setWaterBodyValue}
                          //   setWaterBodyId={setWaterBodyId}
                          //   setPage={setPage}
                            />
                      </div>
                      </Item>
                  </Grid>
                  
                  <Grid item xs={6}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard2} entityType={entityType} height={entityType === 'village' ? "800px" : "1180px"}  state={state} district={district} block={block} village={village}/>
                      </Item>
                  </Grid>

                  <Grid item xs={12}>
                      <Item>
                        <SlidShowCard images={galleryImageList}/>
                      </Item>
                  </Grid>

                  <Grid item xs={12}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard3} entityType={entityType} height="410px"  state={state} district={district} block={block} village={village} />
                      </Item>
                  </Grid>

              </Grid>
            </Container>
        </>
    );
}
