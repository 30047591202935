import React from 'react';
import { Container, Grid, Button, Tooltip } from '@material-ui/core';
import MetabaseDashboard from '../components/MetabaseDashboard';
import {_listMapToOptions, _listToOptions, _valToOption} from '../utils/dropdown'
import InfoIcon from '@material-ui/icons/Info';
import DownloadsComponent from '../components/DownloadsComponent';
import BaselinePhotos from '../components/BaselinePhotos';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { navigate } from 'gatsby';
import MapComponent from '../components/MapComponent';
import Item from '../components/ItemComponent'


export default function WaterBodyComponent({waterBodyName, waterBody, waterBodyMapDetails}) {

    const shapeColorList = [{'name': 'Healthy', 'color': 'blue'},
      {'name': 'Eutrophication', 'color': 'black'},
      {'name': 'Dried-Up', 'color': 'red'}, 
    ]

    return (
        <>
            <Container maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Item>
                          <DownloadsComponent waterBodyMapDetails={waterBodyMapDetails}/>
                        </Item>
                      </Grid>

                      <Grid item xs={12}>
                        <Item>
                          <MapComponent waterBody={waterBody} waterBodyMapDetails={waterBodyMapDetails} />
                        </Item>
                      </Grid>

                      <Grid item xs={12}>
                        <Item>
                          <BaselinePhotos water_body_id={waterBody?.id} />
                        </Item>
                      </Grid>

                    </Grid>
                </Grid>
                
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>

                      <Item style={{height: "300px"}}>
                      <h3>Factsheet</h3>
                      
                      <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div id="basic">
                              <div style={{margin: "5px"}}><b>State:</b> {waterBody?.state}</div>
                              <div style={{margin: "5px"}}><b>District:</b> {waterBody?.district}</div>
                              <div style={{margin: "5px"}}><b>Block:</b> {waterBody?.block}</div>
                              <div style={{margin: "5px"}}><b>Village:</b> {waterBody?.village}</div>
                              <div style={{margin: "5px"}}><b>Unique ID:</b> {waterBody?.unique_id}</div>
                              <div style={{margin: "5px"}}><b>Type of Water Body:</b> {waterBody?.type}</div>
                        </div>

                        <div id="vertical">
                              <div style={{margin: "5px"}}><b>Overall Score:</b> {waterBody?.vertical_values.CLEANLINESS_INDEX['Cleanliness Score'].count}</div>
                              <div style={{margin: "5px"}}><b>Cleanliness Index:</b> {waterBody?.vertical_values.CLEANLINESS_INDEX['Cleanliness Score'].label}</div>
                              <div style={{margin: "5px"}}><b>Type of Intervention:</b> {waterBody?.vertical_values.TYPE_OF_INTERVENTION['Type of Intervention'].label}</div>
                              <div style={{margin: "5px"}}><b>Volume:</b> {waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Volume'].count + " gallon"}
                                {
                                  waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Volume'].count === 0 ?
                                  <Tooltip title={waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Volume'].label}>
                                    <InfoIcon  fontSize="small" fill="red"></InfoIcon>
                                  </Tooltip> :
                                  null
                                }
                              </div>
                              <div style={{margin: "5px"}}><b>Holding Capacity:</b> {waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Holding Capacity'].count + " gallon"}
                                {
                                  waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Holding Capacity'].count === 0 ?
                                  <Tooltip title={waterBody?.vertical_values.VOLUME_AND_HOLDING_CAPACITY['Holding Capacity'].label}>
                                    <InfoIcon  fontSize="small" fill="red"></InfoIcon>
                                  </Tooltip> :
                                  null
                                }
                              </div>
                        </div>

                      </div>
                      </Item>

                    </Grid>

                    <Grid item xs={12}>
                      <Item>
                        <MetabaseDashboard dashboard={38} entityType='water_body' waterBody={waterBodyName} height="800px"/>
                      </Item>
                    </Grid>

                    <Grid item xs={12}>
                      <Item>
                        <h1>For User Managament, Photo Upload and More</h1>
                        <Button variant="text" color="primary"
                          onClick={() => {
                            navigate("/login")
                          }}
                        >
                          <AccountCircleIcon /> Login
                        </Button>
                      </Item>
                    </Grid>

                  </Grid>
                </Grid>
                
                {/* <Grid item xs={12}>
                    <Item>
                      <Button variant="text" color="primary"
                        onClick={() => {
                          navigate("/")
                        }}
                      >
                        <HomeRoundedIcon /> Home
                      </Button>
                    </Item>
                </Grid> */}

            </Grid>
            </Container>
        </>
    );
}
